@import 'styles/bootstrap-variables.scss';
.table{
  width: 100%;
  color: black;
  margin-top: 10px;
  background-color: white;
  td{
    font-weight: lighter;
  }
  tr,th,td{
    border: 1px solid black;
    border-collapse: collapse;
    padding: 5px
  }
}
.pull-right{
  float: right
}
.type{
  font-size: 12px;
  a{
    font-weight: bold;
    text-decoration: underline !important;
  }
}