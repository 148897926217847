@import "styles/bootstrap-variables.scss";
@import "styles/mixins.scss";

.root {
  padding: 1rem;
  h2,
  h3 {
    &:empty {
      margin: 0;
    }
  }
  @include rainbowBorder(top);
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.buttons {
  display: flex;
  bottom: $grid-gutter-width * 2;
  justify-content: flex-end;
  bottom: 0;
  right: 0;

  button,
  a {
    &:last-of-type {
      margin-left: 1rem;
    }
  }
  @include media-breakpoint-down(sm) {
    justify-content: center;
  }
}

.childrenContainer { 
  padding: 1rem ;
  display: flex;
  justify-content: space-around;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}
