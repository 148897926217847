@font-face {
  font-family: 'Proxima';
  font-style: normal;
  src: url('../assets/fonts/ProximaNova/ProximaNova-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Proxima';
  font-weight: 300;
  src: url('../assets/fonts/ProximaNova/ProximaNova-Light.otf') format('opentype');
}
@font-face {
  font-family: 'Proxima';
  font-weight: 500;
  src: url('../assets/fonts/ProximaNova/ProximaNovaCond-Semibold.otf') format('opentype');
}
@font-face {
  font-family: 'Proxima';
  font-weight: 700;  
  src: url('../assets/fonts/ProximaNova/proxima-nova-condensed.otf') format('opentype');
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');
