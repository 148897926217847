.download-pdf { 
  * {
    color: #000 !important;
    background-color: #fff !important;
    background-image: initial !important;
    transition: none;
  }

  .app-footer {
    display: none;
  }

  .app-nav {
    display: none;
  }

  .report-page-container {
    padding-top: 0;
  }
  
  .lists {
    &-tabs {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  
      .tab-pane {
        display: block !important;
      }      
    }
  
    &-selector {
      display: none;
    }
  
    &-table {
      th {
        display: initial;
      }
  
      td {
        text-align: left;   
      }
    }

    &-attendee {
      justify-content: left;
      text-align: left;   
      order: 0;
  
      &-date {
        order: 1;
        font-size: 1rem;  

        &::before {
          content: "   ";
          white-space: pre;
        } 
      }
    }
  }
  
  .event {
    border: 0;

    &-container {
      justify-content: start;
    }
  
    &-image {
      display: none;  
    }
  
    &-info {
      padding: 0;
      justify-content: start;
    }
  }
  
  
  .tools {
    text-align: left;
    padding: 0;

    &-btns {
      display: none;
    }
  }
}