@import "styles/bootstrap-variables.scss";

.app-title {
  display: inline-block;

  color: $primary;

  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.5rem;

  margin-bottom: $grid-gutter-width  / 2;

  .app-title-separator {
    margin-top: 0.75rem;
    border-color: $primary;
    border-width: 2px;
    width: 80%;
  }

}
.d-block.text-right{
  .app-title-separator{
    margin-right: 0;
  }
}
.d-block.text-left{
  .app-title-separator{
    margin-left: 0;
  }
}