@import "styles/bootstrap-variables.scss";

.download-container {
  padding: ($grid-gutter-width * 1) 0;
  background: #F4F4F7;
  text-align: center;

  p {
    color: $primary;
  }

  .stores-buttons-container {
    .btn {
      margin-bottom: $grid-gutter-width / 2;
    }
  }
}

@include media-breakpoint-up(md) {
  .download-container {
    .stores-buttons-container {
      text-align: center;
    }
  }
}