@import "styles/bootstrap-variables.scss";

.tools {
  background-color: #46233d;
  color: #fff;
  text-align: center;
  padding: 3rem 0 2rem 0;

  @media print {
    text-align: left;
    padding: 0;
  }

  &-text {
    &-title {
      font-weight: 500;
      font-size: 1.2rem;
    }
  }

  &-btns {
    display: flex;
    margin-top: 1rem;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: center;
    }

    @media print {
      display: none;
    }
  }

  &-btn {
    padding: 0.5rem 1.5rem;
    text-transform: uppercase;
    background-color: #341a31;
    border: 1px solid #8e748c;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 1rem;
    cursor: pointer;
    margin-top: 1rem;

    &:active {
      transform: scale(1.5);
      outline: none;
    }

    &:focus {
      outline: none;
    }

    &:nth-child(n + 1):not(:last-child) {
      margin-right: 1rem;
    }

    i {
      color: #8e748c;
    }
  }

  &-btn-text {
    margin-right: 1rem;
  }
}

