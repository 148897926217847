@import "styles/bootstrap-variables.scss";

.app-footer {
  @media print {
    display: none;
  }
  
  .app-footer-links-container {
    background: #F6F6F9;
    padding: $grid-gutter-width 0 ($grid-gutter-width / 2);
    text-align: left;

    li {
      margin-bottom: $grid-gutter-width / 4;
  
      a {
        display: flex;
        font-weight: 300;
        font-size: 0.9rem;
  
        .link-icon {
          width: 20px;
          height: 20px;
          background: $primary;
          border-radius: 50%;
          display: flex;
          margin-right: 7.5px;
          align-items: center;
          justify-content: center;
  
          margin-right: $grid-gutter-width / 4;
  
          i {
            color: $white;
            font-size: 12px;
          }
        }
      }
    }
  }

  .row > * {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .app-footer-title {
    color: $primary;
    font-weight: 400;
    font-size: 1.2rem;
  }

  .app-footer-copyright {
    text-align: center;
    font-size: 0.8rem;
    font-weight: 300;

    color: $white;
    padding: ($grid-gutter-width / 2) 0;

    opacity: 0.75;
    
    background-image: linear-gradient(
      #791D77,
      #270044
    );
  }
  a{
    color: white;
    text-decoration: underline;
    &:hover{
      color: rgba(255,255,255, 0.7)
    }
  }
}