@import "styles/bootstrap-variables.scss";
@import "styles/mixins.scss";

.event {
  @include rainbowBorder(top);

  min-height: 200px;
  padding: 3rem 1rem 3rem 1rem;
  background-color: #341e37;

  &-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    @media print {
      justify-content: start;
    }
  }

  &-image {
    width: 200px;
    height: auto;
    border: 3px solid #3f4663;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
    margin-bottom: 1rem;

    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
    }

    @media print {
      display: none;  
    }
  }

  &-info {
    color: #fff;
    align-self: center;
    text-align: center;

    @include media-breakpoint-up(sm) {
      padding-left: 20px;
      text-align: initial;
    }

    @media print {
      padding: 0;
      justify-content: start;
    }
  }

  &-title {
    font-size: 1.2rem;
    padding-bottom: 0.5rem;
    font-weight: 400;
  }

  &-location {
    font-size: 1rem;
    color: #e6d000;
    text-transform: uppercase;
    font-weight: 500;
  }

  @media print {
    border: 0;
  }
}

