@import "styles/bootstrap-variables.scss";
@import "styles/mixins.scss";
.privacy-page-container {
  color: $white;
  > div{
    padding-top: 90px;
    background-image: linear-gradient(#281533 0px, #262e45 90px, #46233d 90px, #281a4d 100%);
    > div.borderRainbow {
      @include rainbowBorder(top);
    }
    > .container{
      margin-top: 50px
    }
  }
}
