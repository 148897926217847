@import "styles/bootstrap-variables.scss";

.lists {
  min-height: 200px;
  background-image: linear-gradient( #46233d, #421043);
  color: #fff;
  padding: 1rem 0 3rem 0;

  &-text {
    text-align: center;
    margin-bottom: 3rem;
  }

  &-nav {
    border-bottom: 1px solid #684965;
    padding: 0 1rem 0 1rem;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &-tabs {
    margin-top: 1rem;
    margin-left: -$grid-gutter-width;
    margin-right: -$grid-gutter-width;

    @include media-breakpoint-up(sm) {
      margin-left: 0;
      margin-right: 0;
    }

    @media print {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .tab-pane {
        display: block !important;
      }      
    }
  }

  &-selector {
    display: flex;
    align-items: center;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    @media print {
      display: none;
    }
  }

  &-select {
    width: 100%;
    color: #fff !important;
    margin-top: 1rem;
    border-radius: 1rem;
    padding: $custom-select-padding-y $custom-select-padding-x;
    background: rgba(255,255,255, 0.05) url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 8px 10px;
    appearance: none;

    @include media-breakpoint-up(sm) {
      margin-top: 0;
      margin-left: 1rem;
      flex: 1; 
    }

    &:focus {
      outline: 0;
    }

    option {
      color: $gray-600;
      background-color: #fff;
    }
  }

  &-table {
    width: 100%;
    margin: 2rem 0;
    background-color: transparent;

    th {
      color: #e6d000;
      text-transform: uppercase;
      font-size: 1.5rem;
      display: none;

      @media print {
        display: initial;
      }
    }

    tr {
      &:nth-child(2n + 1):not(:only-child) {
        background-color:rgba(255,255,255, 0.05); 
      }
    }
  }

  &-attendee {
    padding: 0.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(sm) {
      justify-content: space-around;
    }

    @media print {
      justify-content: left;
      text-align: left;   
      order: 0;
    }

    &-date {
      display: none;
      font-size: 0.7rem;

      @include media-breakpoint-up(sm) {
        display: initial;
      }

      @media print {
        order: 1;
        font-size: 1rem;  

        &::before {
          content: "   ";
          white-space: pre;
        } 
      }
    }
  }
}

