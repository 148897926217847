@import "styles/bootstrap-variables.scss";

.root {
  padding-top: $grid-gutter-width * 3;
  background-image: linear-gradient(
    #281533 0px,
    #262e45 $grid-gutter-width * 3,
    #46233d $grid-gutter-width * 3,
    #281a4d 100%
  );
}

.root {
  section {
    color: #fff;
    height: 70vh;
  }
}

.root {
  display: block;
  h2 {
    display: inline-block;
    text-align: center;
    color: #fff;

    font-weight: 400;
    font-size: 1.5rem;

  }
}

.titleContainer { 
  padding: 2rem;
  text-align: center;
}