@import "styles/bootstrap-variables.scss";

.root {
  padding-top: $grid-gutter-width * 3;
  background-image: linear-gradient(
    #281533 0px,
    #262e45 $grid-gutter-width * 3,
    #46233d $grid-gutter-width * 3,
    #281a4d 100%
  );
}

.root {
  section {
    color: #fff;
  }
}

#confetti {
  height: 1px;
  display: flex;
  justify-content: center;
}

.text {
  padding: 2rem;
  flex-grow: 1;
  flex-basis: 0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @include make-container();

  @include make-container-max-widths();

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.imageContainer {
  max-width: 215px;
  height: auto;
  margin: 20px 0px;
  border-radius: 20px;
  > div {
    border-radius: 32px;
    img {
      width: 202px;
      height: auto;
      border-radius: 37px;
    }
  }
}

.slideContainer{
  margin-top: 20px;
  margin-bottom: 20px;
  @include make-container();

  @include make-container-max-widths();

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.titleContainer { 
  padding: 2rem;
  text-align: center;
}