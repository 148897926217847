.overflow{
  background-color: rgba(0, 0, 0, 0.61);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  > div{
    display: table;
    margin: 0 auto;
    height: 100%;
    > div{
      display: table-cell;
      vertical-align: middle;

      > div {
        text-align: center;
        padding: 20px;
        border-radius: 20px;
        background-color: white;
      }
    }
  }
}
.cancel{
  background-color: #ba0000 !important;
  border-width: 0 !important;
  margin-right: 20px;
  span{
    color: white !important;
  }
}
.save{
  background-color: #138c13 !important;
  border-width: 0 !important;
  span{
    color: white !important;
  }
}
