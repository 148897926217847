@import "styles/bootstrap-variables.scss";

.root {
  padding: 5rem;
  display: flex;
  min-height: 100vh;
  background-image: linear-gradient(#281533 0px, #46233d 100%);
}

.loginButton {
  align-self: center;
  margin: auto;
}