@import "styles/bootstrap-variables.scss";

.app-nav {
  @media print {
    display: none;
  }

  &.navbar {
    position: absolute;
    width: 100%;

    .navbar-brand .navbar-brand-logo {
      height: 65px;
    }

    .navbar-nav {
      li {
        margin-right: $grid-gutter-width / 2;

        a {
          color: $white;
          font-weight: 300;
        }

        &.active {
          border-bottom: 2px solid $white;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .collapse.navbar-collapse {
      background: $primary;
      margin-left: $grid-gutter-width / -2;
      margin-right: $grid-gutter-width / -2;
      padding-left: $grid-gutter-width / 2;
      z-index: 10;
    }
  }
}
