@import 'styles/bootstrap-variables.scss';

.root{
  color: white;
}
.row{
  margin-bottom: 10px;
  input, textarea, select{
    width: 100%;
    height: 35px;
    max-width: 100%;
    background: white !important;
    padding: 5px;
    border-radius: 5px;
    border: 0
  }
  li{
    color: black;
  }
}
.save{
  float: right;
  background-color: #138c13 !important;
  border-width: 0 !important;
  span{
    color: white !important;
  }
}
.cancel{
  float: right;
  background-color: #ba0000 !important;
  border-width: 0 !important;
  margin-right: 20px;
  span{
    color: white !important;
  }
}
.error{
  font-size: 10px;
  color: #ba0000;
}