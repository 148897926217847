@import "styles/bootstrap-variables.scss";

.term-box-container {
  position: relative;
  background: $white;
  border-radius: $border-radius-lg;

  padding: ($grid-gutter-width / 1.5) $grid-gutter-width;
  margin: 0 auto ($grid-gutter-width * 2);
  box-shadow: rgba(82, 18, 88, 0.3) 0px 4px 15px 0px;

  max-height: $grid-gutter-width * 15;
  overflow-y: scroll;
  overflow-x: hidden;

  h2 {
    color: #3B495E;
    font-size: 1.3rem;
    font-weight: 400;
  }

  h3 {
    color: #646F7E;
    font-size: 1rem;
    text-align: center;
    font-weight: 300;
    margin: ($grid-gutter-width / 1.5) 0 ($grid-gutter-width / 1.5);
  }

  p, li {
    margin-bottom: 1rem;
    color: #646F7E;
    font-size: 1rem;
    text-align: justify;
    font-weight: 300;
  }
}

@include media-breakpoint-up(lg) {
  .term-box-container {
    width: 100% !important;
  }
}