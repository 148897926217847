@import "styles/bootstrap-variables.scss";

.report {
  transition: all 0.5s ease;
  padding-top: $grid-gutter-width * 3;
  background-image: linear-gradient( #281533 0px, #262e45 $grid-gutter-width * 3);
  
  * {
    transition: all 0.5s ease;
  }

  &-error {
    height: 50vh;
    background-color:#46233d;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }

  @media print {
    padding-top: 0;
  }
}
