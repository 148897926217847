.form {
  display: flex;

  input {
    background-color: #fff;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    border-style: solid;
    border-width: 1px;
    padding: 0 1rem;

    &:focus {
      background-color: #fff;
    }

    &::placeholder {
      color: #000;
    }
  }

  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-right: -1px;
  }
}

