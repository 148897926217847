@import 'styles/bootstrap-variables.scss';

.admin {
  color: white;
  margin-top: 20px;
  .search {
    color: white;
    margin-left: 10px;
    display: inline-block;
    font-size: 20px;
    cursor: pointer;
  }
  input{
    width: 300px;
  }
}
.searchBox{
  line-height: 1.9;
}