@import "styles/bootstrap-variables.scss";

.root {
  position: relative;
  margin-bottom: 1rem;
  overflow: hidden;
  border-radius: 37px;
  @include media-breakpoint-up(md) {
    margin-right: 1rem;
    margin-bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0; 
    right: 0;
    bottom: 0; 
    left: 0;
    background-image: url('../../assets/images/frame.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  img {
    padding: 5% 8% 5% 4%;
    background-color: #000;
    border-radius: 20px;
  }
}