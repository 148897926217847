@import "styles/bootstrap-variables.scss";

.app-btn {
  padding: 0.25rem 1.5rem;
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.3);

  .button-text,
  i {
    color: $primary;
  }

  i {
    font-size: $grid-gutter-width / 2;
    top: -1px;
    position: relative;

    & + .button-text:not(:empty) {
      margin-left: 0.5rem;
    }
  }

  &.btn-outline-light,
  &.btn-primary {
    .button-text,
    i {
      color: $white;
    }
  }

  &.btn-outline-primary:hover {
    .button-text,
    i {
      color: $white;
    }
  }

  &.btn-outline-light:hover {
    .button-text,
    i {
      color: $primary;
    }
  }

  &.color-white {
    .button-text,
    i {
      color: $white;
    }
  }

  &.color-black {
    .button-text,
    i {
      color: $black;
    }
  }

  &.rounded-circle {
    padding: 0.75rem 0.5rem;
    width: 3rem;
    height: 3rem;
  }
}
