@import "styles/bootstrap-variables.scss";

.root {
  padding-top: $grid-gutter-width * 3;
  background-image: linear-gradient(
    #281533 0px,
    #262e45 $grid-gutter-width * 3,
    #46233d $grid-gutter-width * 3,
    #281a4d 100%
  );
}

.root {
  section {
    color: #fff;
  }
}

.root {
  h3 {
    display: inline-block;

    color: #fff;

    font-weight: 400;
    font-size: 1.5rem;

  }
}

.banner {
  img {
    width: 1rem !important;
  }
}

.input-group-prepend button {
  border-top-right-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.titleContainer { 
  padding: 2rem;
  text-align: center;
}

.text {
  padding: 2rem;
  flex-grow: 1;
  flex-basis: 0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @include make-container();

  @include make-container-max-widths();

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.slideContainer{
  margin-top: 20px;

  @include make-container();

  @include make-container-max-widths();

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.tosContainer { 

  display: flex;
  justify-content: space-around;

  section {
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

}


.imageContainer {
  padding: 1rem 2rem 2rem 2rem;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: center;
  text-align: center;
  height: 50%;

  img, div {
    display: inline-block;
    height: auto;
    width: 100%;
  
    @include media-breakpoint-up(md) {
      max-width: 23%;
    }
  
    &:last-of-type:first-of-type {
      max-width: 170px;
      height: 305px;
    }
  
    &[src$=".svg"] {
      width: 300px;
    }
  }
}