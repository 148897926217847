@import './fonts.scss';
@import './font-awesome.scss';
@import './bootstrap-variables.scss';
@import './pdf.scss';
@import '~font-awesome/scss/font-awesome';
@import '~bootstrap/scss/bootstrap';
@import '~react-toastify/dist/ReactToastify.css';

#root {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}

section {
  padding: 3rem 0;

  @include media-breakpoint-up(md) {
    padding: 5rem 0;
  }
}

::-moz-selection {
  color: $white;  
  background: $primary;
}

::selection {
  color: $white;
  background: $primary;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  opacity: 0.3;
  background: linear-gradient(rgb(203, 97, 164), rgb(105, 20, 136), rgb(49, 154, 209));
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
  background: rgba(255, 255, 255, 0.6); 
}

@media print {
  * {
    color: #000 !important;
  }
}
.slick-prev, .slick-next{
  @include media-breakpoint-up(lg) {
    &:before{
      font-size: 60px;
    }
    width: 60px;
    height: 60px;
  }
}
.slick-next{
  @include media-breakpoint-up(lg) {
    right: -60px;
  }
}
.slick-prev{
  @include media-breakpoint-up(lg) {
    left: -60px;
  }
}