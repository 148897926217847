@import "styles/bootstrap-variables.scss";

.app-container {
  width: 100%;
  height: 100%;
  position: relative;

  .page-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column;
    flex-flow: column;
  }
}