@import "styles/bootstrap-variables.scss";

.root {
  padding: 2rem 0rem;

  h2,
  h3 {
    &:empty {
      margin: 0;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @include make-container();

  @include make-container-max-widths();

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.imageContainer {
  padding: 1rem 2rem 2rem 2rem;
  flex-grow: 1;
  flex-basis: 0;
  margin-left: -5px;
  margin-right: -5px;
  img, div {
    display: inline-block;
    height: auto;
    width: 100%;
    margin: 2px 5px;
  
    @include media-breakpoint-up(md) {
      max-width: 31%;
    }
    @include media-breakpoint-up(lg) {
      max-width: 23%
    }
    
    &:last-of-type:first-of-type {
      max-width: 100%;
      width: 260px;
    }

  
    &[src$=".svg"] {
      width: 300px;
    }
  }
}

.text {
  padding: 2rem;
  flex-grow: 1;
  flex-basis: 0;
}